<script setup lang="ts">
import type { ButtonHTMLAttributes } from 'vue';
import type { ButtonVariant } from '@/types';
import { computed } from 'vue';
import BxSpinner from '@/components/common/BxSpinner.vue';

const props = withDefaults(
  defineProps<{
    id?: string;
    type?: ButtonHTMLAttributes['type'];
    variant?: ButtonVariant;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    id: '',
    type: 'button',
    variant: undefined,
    loading: false,
    disabled: false,
  },
);

const classes = computed(() => {
  switch (props.variant) {
    case 'primary':
      return [
        'border-transparent',
        'text-bridgit-primaryBtnText',
        'bg-bridgit-vibrantGreen',
        'drop-shadow-md',
        props.disabled || props.loading ? 'opacity-50' : 'hover:bg-green-500 active:bg-green-600',
      ];
    case 'secondary':
      return [
        'border-black',
        'text-bridgit-navy',
        'bg-white',
        'drop-shadow-md',
        props.disabled || props.loading ? 'opacity-50' : 'hover:bg-bridgit-lightBlue active:bg-blue-400',
      ];
    case 'tertiary':
      return [
        'border-gray-200',
        'text-gray-700',
        'bg-white',
        'drop-shadow',
        props.disabled || props.loading ? 'opacity-50' : 'hover:bg-gray-100 active:bg-gray-200',
      ];
    case 'link':
      return [
        'border-transparent',
        'text-bridgit-navy',
        'underline decoration-solid underline-offset-2',
        'bg-transparent',
        props.disabled || props.loading ? 'opacity-50' : 'hover:text-bridgit-green',
      ];
    default:
      return [
        'border-gray-300',
        'text-bridgit-navy',
        'bg-gray-200',
        'drop-shadow',
        props.disabled || props.loading ? 'opacity-50' : 'hover:bg-gray-300 active:bg-gray-400',
      ];
  }
});
</script>

<template>
  <button
    :id="id"
    :data-test-id="id"
    :type="props.type"
    class="inline-flex items-center rounded-md border px-4 py-2 text-sm transition focus:outline-none focus-visible:ring-2 focus-visible:ring-bridgit-royalBlue focus-visible:ring-offset-2"
    :class="classes"
    :disabled="props.disabled || props.loading"
  >
    <div v-if="props.loading" class="absolute inset-0 flex justify-center"><BxSpinner /></div>
    <div class="inline-flex items-center whitespace-nowrap" :class="{ invisible: props.loading }">
      <slot />
    </div>
  </button>
</template>
