<script setup lang="ts">
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import type { ButtonVariant } from '@/types';

const props = withDefaults(
  defineProps<{
    to?: RouteLocationRaw;
    href?: string;
    variant?: ButtonVariant;
    disabled?: boolean;
  }>(),
  {
    to: undefined,
    href: undefined,
    variant: undefined,
    disabled: false,
  },
);

const tag = computed(() => (props.to ? 'router-link' : 'a'));
const linkProp = computed(() => (props.to ? 'to' : 'href'));
const linkValue = computed(() => (props.to ? props.to : props.href));

const classes = computed(() => {
  switch (props.variant) {
    case 'primary':
      return [
        'border-transparent',
        'text-bridgit-primaryBtnText',
        'bg-bridgit-vibrantGreen',
        'drop-shadow-md',
        props.disabled ? 'opacity-50' : 'cursor-pointer hover:bg-green-500 active:bg-green-600',
      ];
    case 'secondary':
      return [
        'border-black',
        'text-bridgit-navy',
        'bg-white',
        'drop-shadow-md',
        props.disabled ? 'opacity-50' : 'cursor-pointer hover:bg-bridgit-lightBlue active:bg-blue-400',
      ];
    case 'tertiary':
      return [
        'border-gray-200',
        'text-gray-700',
        'bg-white',
        'drop-shadow',
        props.disabled ? 'opacity-50' : 'cursor-pointer hover:bg-gray-100 active:bg-gray-200',
      ];
    case 'link':
      return [
        'border-transparent',
        'text-bridgit-navy',
        'underline decoration-solid underline-offset-2',
        'bg-transparent',
        props.disabled ? 'opacity-50' : 'cursor-pointer hover:text-bridgit-green',
      ];
    default:
      return [
        'border-gray-300',
        'text-bridgit-navy',
        'bg-gray-200',
        'drop-shadow',
        props.disabled ? 'opacity-50' : 'cursor-pointer hover:bg-gray-300 active:bg-gray-400',
      ];
  }
});
</script>

<template>
  <component
    :is="tag"
    :[linkProp]="linkValue"
    class="inline-flex items-center rounded-md border px-4 py-2 text-sm transition focus:outline-none focus-visible:ring-2 focus-visible:ring-bridgit-royalBlue focus-visible:ring-offset-2"
    :class="classes"
    :disabled="props.disabled"
  >
    <div class="inline-flex items-center">
      <slot />
    </div>
  </component>
</template>
